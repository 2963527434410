<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">覆盖物管理</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleAdd" icon="plus" type="primary">新增覆盖物</a-button>
    </div>
    <a-card :bordered="false" class="m-t-24">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper m-b-none">
          <a-form layout="inline">
            <a-form-item label="名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入名称"/>
            </a-form-item>
            <a-form-item label="覆盖物类型">
              <a-select
                allowClear
                showSearch
                v-model="searchParams.type"
                placeholder="请选择覆盖物类型"
                @change="search"
              >
                <a-select-option
                  v-for="(item, index) in OverlayTypeList"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="备注">
              <a-input v-model="searchParams.memo" @change="search" placeholder="请输入备注"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-card>
    <a-card :bordered="false" class="m-t-24">
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <!--<a-button @click="handleEdit(record.id)" shape="circle" icon="edit" size="small"></a-button>
          <a-divider type="vertical"/>
          <a-button type="danger" @click="handleDelete(record.id)" shape="circle" icon="delete"
                    size="small"></a-button>-->
          <a @click="handleEdit(record.id)" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" @click.stop>删除</a>
        </template>
        <template slot="typeSlot" slot-scope="text, record">
          <a-icon type="environment" v-if="record.type === 'marker'"/>
          <a-icon type="dash" v-if="record.type === 'polyline'"/>
          <a-icon type="radius-setting" v-if="record.type === 'polygon'"/>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      title="添加覆盖物基本信息"
      :visible="addModal"
      :maskClosable="false"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal"
    >
      <Form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></Form>
    </a-modal>
    <!--修改Model-->
    <a-modal
      title="修改覆盖物基本信息"
      :visible="editModal"
      :maskClosable="false"
      centered
      @ok="handleEditOk"
      @cancel="closeEditModal"
    >
      <Form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></Form>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUD from '../../common/mixins/entityCRUD'
  import { overlayColumns, overlayTypeList } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import Form from './Form'

  export default {
    name: 'List',
    mixins: [entityCRUD],
    components: { PageLayout, Form },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.overlay,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: '',
          type: '',
          memo: '',
          systemModule: ''
        },
        OverlayTypeList: overlayTypeList(),
        initColumns: overlayColumns()
      }
    }
  }
</script>
